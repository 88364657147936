import { info } from "@eduframe/legacy/common/utils/console";

// Import jquery global
import "@eduframe/legacy/common/globals_node_modules/jquery";
import "bootstrap-sass/assets/javascripts/bootstrap";
import "@/lib/appsignal";

// Disable submit buttons on submit
import "@eduframe/legacy/lib/rails_ujs/init";

import $ from "jquery";

$(() => {
  info("==== login pack loading finished");
});
